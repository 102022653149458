<template>
  <div class="mb-lg-0">
    <div>
      <div class="item2-gl">
        <div class="row">
          <template v-if="list == null || (list.length == 0 && !isLoad)">
            <div class="text-center"><h3>Sonuç Bulunamadı</h3></div>
          </template>
          <template v-if="list == null || (list.length == 0 && isLoad)">
            <div class="text-center"><h3>Sonuçlar getiriliyor...</h3></div>
          </template>
          <div
            class="col-lg-4 col-md-12 col-xl-4"
            v-for="(item, i) in list"
            :key="'user' + i"
          >
            <div class="card tItem card-body mb-4 center-item">
              <div class="item-card-imgs" style="text-align: center">
                <router-link :to="'/center/' + item.shortlink">
                  <lazy-image
                    :src="
                      'https://storage.terapivitrini.com/' +
                      item.profile_image_url
                    "
                    :alt="item.fullname"
                    class="brround avatar-xxl"
                    style="width: 150px; height: 150px"
                /></router-link>
              </div>
              <div class="mt-5">
                <div class="item-card2">
                  <div class="item-card2-desc text-center">
                    <div class="item-card2-text" >
                      <router-link
                        :to="'/center/' + item.shortlink"
                        class="text-dark"
                        ><h2
                          class="font-weight-bold mb-1"
                          style="font-size: 20px"
                        >
                          {{ item.fullname }}
                        </h2></router-link
                      >
                      <StarPos
                        :pos="Math.ceil(item.total_score / item.total_comment)"
                        :id="item.user_id"
                      />
                    </div>
                    <p class="fs-16">{{ item.expert }}</p>
                    <router-link
                      :to="'/center/' + item.shortlink"
                      class="btn btn-primary btn-sm"
                      >Görüntüle</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <style>

</style>
  <script>
import Sidebar from "../../ProfileDetail/Components/Sidebar.vue";
import StarPos from "../../../components/StarPos.vue";
export default {
  props: ["list", "isLoad"],
  components: {
    Sidebar,
    StarPos,
  },
};
</script>