<template>
  <div class="card" v-if="item != ''">
    <div class="card-body" style="padding: 10px">
      <div id="myCarousel" class="owl-carousel owl-carousel-icons">
        <div v-for="(a, i) in item" :key="'user' + i">
          <div class="card overflow-hidden" style="box-shadow:none">
            <div class="item-card9">
              <span
                class="badge badge-warning"
                style="
                  position: absolute;
                  right: 5px;
                  top: 5px;
                  z-index: 500000;
                "
                >Reklam</span
              >
              <a :href="a.link"></a>
              <img
                :src="'https://storage.terapivitrini.com/' + a.image.path"
                :alt="a.sub_title"
                class="w-100"
                style="width: 100%; height:100%;"
              />
            </div>
            <div class="card-body">
              <div class="item-card2">
                <div class="item-card2-desc text-center">
                  <div class="item-card2-text">
                    <a :href="a.link" class="text-dark"  rel="nofollow"
                      ><h2
                        class="font-weight-bold mb-1"
                        style="font-size: 20px"
                      >
                        {{ a.title }}
                      </h2></a
                    >
                  </div>
                  <p class="fs-16">{{ a.sub_title }}</p>
                  <span v-for="item,i in a.cons" :key="i" style="margin-right:5px">
                    <a :href="item.link" rel="nofollow" target="_blank" class="btn btn-white btn-sm"
                    >{{ item.title }}</a
                  >
                  </span>
                  <a v-if="a.link!=null" :href="a.link" rel="nofollow" target="_blank" class="btn btn-white btn-sm"
                    >İncele</a
                  >
                  
                </div>
              </div>
            </div>
          </div>
          <template v-if="item.length-1==i">{{load()}}</template>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="false" style="padding: 10px">
      <div id="myCarousel" class="owl-carousel owl-carousel-icons">
        <div>
          <div class="card overflow-hidden">
            <div class="item-card9">
              <router-link to="/iletisim"></router-link>
              <img
                src="https://terapivitrini.com/assets/images/empty_ads4.jpeg"
                class="w-100"
                style="width: 100%; height:100%;"
              />
            </div>
            <div class="card-body">
              <div class="item-card2">
                <div class="item-card2-desc text-center">
                  <div class="item-card2-text">
                    <router-link to="/ileitisim" class="text-dark"
                      ><h2
                        class="font-weight-bold mb-1"
                        style="font-size: 20px"
                      >
                        Sponsor Alanı
                      </h2></router-link
                    >
                  </div>
                  <p class="fs-16">Reklam vermek için</p>
                  <router-link
                    to="/iletisim"
                    target="_blank"
                    class="btn btn-white btn-sm"
                    >İrtibat</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  created() {},
  data() {
    return {
      title: "",
      description: "",
      selectedView: "GENERALINFO",
      pos: 0,
      list: [],
      isLoadUser: true,
      phoneShow: false,
      phoneDialogText: "Şimdi Ara",
    };
  },
  methods: {
    load(){
      setTimeout(() => {
        (function ($) {
          var owl = $(".owl-carousel-icons");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: true,
            autoplay: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Owl-carousel-icons2
          var owl = $(".owl-carousel-icons2");
          owl.owlCarousel({
            loop: true,
            rewind: false,
            margin: 25,
            animateIn: "fadeInDowm",
            animateOut: "fadeOutDown",
            autoplay: false,
            autoplayTimeout: 5000, // set value to change speed
            autoplayHoverPause: true,
            dots: false,
            nav: true,
            autoplay: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
              600: {
                items: 2,
                nav: true,
              },
              1300: {
                items: 4,
                nav: true,
              },
            },
          });
  
          // ______________Owl-carousel-icons3
          var owl = $(".owl-carousel-icons3");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              1000: {
                items: 2,
              },
            },
          });
  
          // ______________Owl-carousel-icons4
          var owl = $(".owl-carousel-icons4");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 3,
              },
              1000: {
                items: 6,
              },
            },
          });
  
          // ______________Owl-carousel-icons5
          var owl = $(".owl-carousel-icons5");
          owl.owlCarousel({
            loop: true,
            rewind: false,
            margin: 25,
            animateIn: "fadeInDowm",
            animateOut: "fadeOutDown",
            autoplay: false,
            autoplayTimeout: 5000, // set value to change speed
            autoplayHoverPause: true,
            dots: true,
            nav: false,
            autoplay: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
              600: {
                items: 2,
                nav: true,
              },
              1300: {
                items: 4,
                nav: true,
              },
            },
          });
  
          // ______________Owl-carousel-icons6
          var owl = $(".owl-carousel-icons6");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              1000: {
                items: 3,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel2
          var owl = $(".testimonial-owl-carousel2");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            autoplay: true,
            dots: false,
            animateOut: "fadeOut",
            smartSpeed: 450,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel3
          var owl = $(".testimonial-owl-carousel3");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            autoplay: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel4
          var owl = $(".testimonial-owl-carousel4");
          owl.owlCarousel({
            margin: 25,
            loop: true,
            nav: false,
            autoplay: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
              },
            },
          });
  
          // ______________Testimonial-owl-carousel
          var owl = $(".testimonial-owl-carousel");
          owl.owlCarousel({
            loop: true,
            rewind: false,
            margin: 25,
            autoplay: true,
            animateIn: "fadeInDowm",
            animateOut: "fadeOutDown",
            autoplay: false,
            autoplayTimeout: 5000, // set value to change speed
            autoplayHoverPause: true,
            dots: false,
            nav: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
            },
          });
        })(jQuery);
      }, 5);
    }
  },
  watch: {
    "item": function () {
      this.load()
    },
  },
  components: {},
  mounted() {
  },
};
</script>