<template>
  <ul class="pagination mb-5">
    <li
      v-if="currentpage > 1"
      :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
    >
      <router-link :to="preload + (parseInt(currentpage) - 1)" class="page-link"
        >Önceki</router-link
      >
    </li>
    <div v-for="(s, i) in pagesize" :key="i">
      <li
        @click="backToTop"
        v-if="currentpage > i - 2 && currentpage < i + 4"
        :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
      >
        <router-link :to="preload + (i + 1)" class="page-link">{{
          i + 1
        }}</router-link>
      </li>
    </div>
    <li
      v-if="currentpage != pagesize"
      :class="
        'page-item page-next ' + (currentpage == pagesize ? 'disabled' : '')
      "
    >
      <router-link v-if="pagesize == 1" :to="preload + (parseInt(currentpage) + 1)" class="page-link"
        >Sonraki</router-link
      >
    </li>
  </ul>
</template>
<script>
export default {
  props: ["currentpage", "pagesize", "preload"],
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
};
</script>>