<template>
    <div class="col-xl-3 col-lg-4 col-md-12">
      <Item1 :item="ads" />
      <div class="filter">
        <div class="card">
          <div class="card-body">
            <div class="input-group">
              <input
                type="text"
                class="form-control br-ts-7 br-bs-7"
                placeholder="Merkezler içinde"
                @keyup="filterPage"
                v-model="search"
              />
              <div class="input-group-text border-0 bg-transparent p-0">
                <button
                  @click="filter"
                  type="button"
                  class="btn btn-primary br-te-7 br-be-7"
                >
                  Ara
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Kategoriler</h3>
          </div>
          <div class="card-body"
          :style="'transition: all 1s ease 0s;overflow: hidden;height:' +height / bol +'px;'">
            <div id="listBox">
              <div
                class="filter-product-checkboxs"
                v-for="(i, a) in category"
                :key="a"
              >
                <label class="custom-control form-checkbox mb-3">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :name="'checkbox' + a"
                    :value="i.id"
                    @click="selectedCategory(i.id)"
                  />
                  <span class="custom-control-label" :for="'checkbox' + a">
                    {{ i.experience_name
                    }}<span class="label label-secondary float-end">{{
                      i.count
                    }}</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div @click="loadmore" class="loadmore">
            <i v-if="calenderToggleBool" class="fa fa-angle-down"></i>
            <i v-if="!calenderToggleBool" class="fa fa-angle-up"></i>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Hizmet Türü</h3>
          </div>
          <div class="card-body">
            <div class="filter-product-checkboxs">
              <label class="custom-control form-checkbox mb-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="facetoface"
                  value="1"
                  @click="selectedEducationType(1)"
                />
                <span class="custom-control-label"> Yüz yüze </span>
              </label>
              <label class="custom-control form-checkbox mb-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="online"
                  value="2"
                  @click="selectedEducationType(2)"
                />
                <span class="custom-control-label"> Online </span>
              </label>
              <label class="custom-control form-checkbox mb-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="online"
                  value="2"
                  @click="selectedEducationType(3)"
                />
                <span class="custom-control-label"> Evde </span>
              </label>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">
            <button @click="filter" class="btn btn-secondary btn-block">
              Filtreyi Uygula
            </button>
          </div>
        </div>
      </div>
      <div class="mobile-filter">
        <div class="open-filter-dialog" @click="openFilter">
          <i class="si si-equalizer"></i>
        </div>
        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterModal"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div
              class="modal-content"
              style="overflow: scroll; overflow-x: hidden; max-height: 450px"
            >
              <div class="modal-body">
                
                <div class="input-group mb-5">
                  <input
                    type="text"
                    class="form-control br-ts-7 br-bs-7"
                    placeholder="Merkez içinde"
                    v-model="search"
                  />
                  <div class="input-group-text border-0 bg-transparent p-0">
                    <button
                      @click="filter"
                      type="button"
                      class="btn btn-primary br-te-7 br-be-7"
                    >
                      Ara
                    </button>
                  </div>
                </div>
                
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Kategoriler</h3>
                  </div>
                  <div class="card-body">
                    <div class="" id="container">
                      <div
                        class="filter-product-checkboxs"
                        v-for="(i, a) in category"
                        :key="a"
                      >
                        <label class="custom-control form-checkbox mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :name="'checkbox' + a"
                            :value="i.id"
                            @click="selectedCategory(i.id)"
                          />
                          <span
                            class="custom-control-label"
                            :for="'checkbox' + a"
                          >
                            {{ i.experience_name
                            }}<span class="label label-secondary float-end">{{
                              i.count
                            }}</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Hizmet Türü</h3>
                  </div>
                  <div class="card-body">
                    <div class="filter-product-checkboxs">
                      <label class="custom-control form-checkbox mb-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="facetoface"
                          value="1"
                          @click="selectedEducationType(1)"
                        />
                        <span class="custom-control-label"> Yüz yüze </span>
                      </label>
                      <label class="custom-control form-checkbox mb-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="online"
                          value="2"
                          @click="selectedEducationType(2)"
                        />
                        <span class="custom-control-label"> Online </span>
                      </label>
                      <label class="custom-control form-checkbox mb-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="online"
                          value="2"
                          @click="selectedEducationType(3)"
                        />
                        <span class="custom-control-label"> Evde </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="closeFilter"
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Kapat
                </button>
                <button type="button" @click="filter" class="btn btn-primary">
                  Filtreyi Uygula
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style scoped>
  .loadmore {
  text-align: center;
  font-size: 20px;
  box-shadow: 0px -16px 15px -14px #000000;
  position: inherit;
}
.loadmore:hover {
  cursor: pointer;
}
  .in {
    text-align: center;
    font-weight: 100;
    border: 1px solid #dfdfdf !important;
    padding: 6px;
    background: #fff;
    margin: 5px;
    float: left;
    width: 46%;
  }
  .mobile-filter {
    display: none;
  }
  .open-filter-dialog {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 12px;
    background: #c488a45e;
    border-radius: 11px;
    top: 50%;
    text-align: center;
    font-size: 34px;
    z-index: 1050;
    color: #fff;
  }
  @media (max-width: 990px) {
    .filter {
      display: none;
    }
    .mobile-filter {
      display: block;
      cursor: pointer;
    }
  }
  </style>
  <script>
  import Item1 from "../../../components/Ads/Item1.vue";
  export default {
    props: ["category","ads"],
    created(){
      setTimeout(() => {
          this.height = document.getElementById("listBox").offsetHeight;
        }, 1000);
    },
    data() {
      return {
        search: "",
        sCategory: [],
        eType: [],
        height: 0,
        bol: 6,
        calenderToggleBool: true,
      };
    },
    methods: {
      filterPage(){
        if(event.key==='Enter'){
          this.filter()
        }
      },
      openFilter() {
        $("#filterModal").modal("show");
      },
      closeFilter() {
        $("#filterModal").modal("hide");
      },
      selectedCategory(id) {
        if (this.sCategory.indexOf(id) < 0) {
          this.sCategory.push(id);
        } else {
          let find = this.sCategory.indexOf(id);
          this.sCategory.splice(find, 1);
        }
      },
      selectedEducationType(id) {
        if (this.eType.indexOf(id) < 0) {
          this.eType.push(id);
        } else {
          let find = this.eType.indexOf(id);
          this.eType.splice(find, 1);
        }
      },
      filter() {
        let filterData = {
          search: this.search,
          category: this.sCategory.toString(),
          type: this.eType.toString(),
        };
        this.$emit("response", filterData);
      },
      loadmore() {
        if (this.bol == 1) {
          this.calenderToggleBool = true;
          this.bol = 6;
        } else {
          this.calenderToggleBool = false;
          this.bol = 1;
        }
      },
    },
    mounted() {},
    components: { Item1 },
  };
  </script>