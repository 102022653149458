<template>
  <div>
    <section>
      
      <Breadcrumb
      title="Terapi Merkezi"
      :array="[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: true, url: '/center', text: 'Kurum' },
      ]"
    />
    </section>
    <!--Breadcrumb-->
    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-12" id="mobileEx">
            <!--Add lists-->
            <div class="mb-lg-1">
              <template v-if="isExpertLoad">
                <ProfileItemSkeleton :list="8" />
              </template>
              <template v-if="!isExpertLoad">
                <ProfileItem :list="list" />
              </template>
              <div class="center-block text-center mt-5">
                <Pagination
                  :currentpage="currentpage"
                  :pagesize="pagesize"
                  :preload="'/center/page/'"
                />
              </div>
            </div>
            <!--Add lists-->
          </div>

          <SideBarList :category="category" :ads="ads" @response="filteredData($event)"/>
        </div>
      </div>
    </section>
    <!--Add Listing-->
  </div>
</template>
  <style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.open-filter-dialog {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 12px;
  background: #c488a4cb;
  border-radius: 11px;
  top: 50%;
  text-align: center;
  font-size: 34px;
  z-index: 1050;
  color: #fff;
}
</style>
  <script>
import Search from "../../components/Search.vue";
import Experience from "./../SearchPages/Components/Experience.vue";
import ProfileItem from "./../SearchPages/Components/ProfileCenterList.vue";
import ProfileItemSkeleton from "./../SearchPages/Components/ProfileItemSkeleton.vue";
import Pagination from "../../components/Pagination.vue";
import Item1 from "../../components/Ads/Item1.vue";
import SideBarList from "./Components/SidebarList.vue";
import Breadcrumb from '../../components/Breadcrumb.vue';
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.getList();
  },
  data() {
    return {
      ads: [],
      cityList: [],
      districtList: [],
      info: [],
      list: [],
      city: "",
      district: "",
      search: "",
      serviceList: [],
      currentpage: 1,
      perpage: 12,
      totalsize: 0,
      pagesize: 0,
      isExpertLoad: true,
      selectedService:"",
      selectedServiceType:"",
      filter: "",
      filtered: "",
      title: "",
      description: "",
    };
  },
  watch: {
    "$route.params.page": function () {
      this.getList();
    },
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    filteredData(e){
      this.search=e.search;
      this.selectedService = e.category;
      this.selectedServiceType=e.type;
      this.getList();
    },
    getList() {
      if (this.$route.params.page) {
        this.currentpage = this.$route.params.page;
      }
      let experienceInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        services:this.selectedService,
        service_type:this.selectedServiceType,
        location: this.$route.params.city,
        province: this.$route.params.district,
        search: this.search,
        filtered: this.filtered,
      };
      this.$store.dispatch("centerUserFilter", experienceInfo).then((value) => {
        this.title = "Tüm Merkezler - Terapi Vitrini";
        this.description =
          "Tüm Merkezler - ihtiyacınız olan terapiyi ve terapisti bulun";
          let _this = this;
          $(document).ready(function () {
            let content = document.getElementById("appcontent").innerHTML;
            _this.$store.dispatch("setMetaContent", [
              _this.title,
              _this.description,
              "",
              "",
              "",
              content,
            ]);
          });
        this.category=value.category;
        this.ads = value.ads;
        this.list = value.users;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.filter = [];
        this.filter = value.filter;
        this.isExpertLoad = false;
      });
    },
    locationChange() {
      let city = document.querySelector("select[name=city]").value;
      this.districtList = this.cityList.filter(
        (country) => country.shortname == city
      )[0].districts;
      this.$router.push("/terapist-filter/" + city + "/");
    },
    disctrictChange() {
      let district = document.querySelector("select[name=district]").value;
      this.$router.push(
        "/terapist-filter/" + this.$route.params.city + "/" + district + "/"
      );
    },
  },
  components: {
    Experience,
    ProfileItem,
    ProfileItemSkeleton,
    Search,
    Pagination,
    Item1,
    SideBarList,
    Breadcrumb,
  },
  mounted() {
    this.backToTop();
    let serviceInfo = {};
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.serviceList = value;
    });
  },
};
</script>