<template>
  <div class="form-group col-xl-6 col-lg-5 col-md-12 mb-0">
    <input
      @keyup="dialog"
      type="text"
      class="form-control input-lg border-end-0 inputActive"
      autocomplete="off"
      id="search"
      v-model="$store.state.search"
      style="z-index: 5001; position: inherit; border: none !important;border-radius: 20px !important;"
      placeholder="Terapi, terapist, kurum, hizmet..."
    />
  </div>
</template>
<style>
#search::placeholder{
  color:#c5c5c5
}
.blank {
  margin-top: 10px;
}

.list-group-item {
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
}

.float-right {
  float: right;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c589a5 #dedede;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #ac6e8b;
  border-radius: 20px;
  border: 3px none #ffffff;
}
</style>
<script>
export default {
  props: [],
  created() {
    var time=0;
    setInterval(() => {
      var i = 0;
      var txt = this.list[time];
      var speed = 50;
      this.typeWriter(i, txt, speed);
      time++;
      if(time==this.list.length){
        time=0
      }
    }, 1500);
  },
  data() {
    return {
      placeholder: "",
      list: ["baş ağrısı...", "bel fıtığı...", "fibromiyamji...", "migren..."],
      currentpage: 1,
      perpage: 4,
      totalsize: 0,
      pagesize: 0,
    };
  },
  methods: {
    typeWriter(i, txt) {
      var speed = 50;
      var a = i;
      if (a < txt.length) {
        this.placeholder += txt.charAt(a);
        a++;
        setTimeout(() => {
          this.typeWriter(a, txt, speed);
        }, speed);
      } else {
        this.placeholder = "";
      }
    },
    dialog(e) {
      if (e.key == "Enter") {
        document.getElementById("append").style.display = "none";
        document.getElementById("selectedFind").style.display = "none";
      }
      if (this.find && this.$store.state.search.length == 0) {
        this.find = false;
        document.getElementById("append").style.display = "none";
        document.getElementById("selectedFind").style.display = "none";
      } else {
        if (
          this.$store.state.search != "" &&
          (this.$store.state.search.length >= 3 || e.keyCode == 32)
        ) {
          let experienceInfo = {
            search: this.$store.state.search,
            begin: (this.currentpage - 1) * this.perpage,
            perpage: this.perpage,
          };
          this.$store
            .dispatch("serviceFilter", experienceInfo)
            .then((value) => {
              this.list = value.users;
              this.totalsize = value.size;
              this.pagesize = value.pages;
              this.$store.state.searchItem = [];
              this.$store.state.searchItem = value;
              this.find = true;
              document.getElementById("append").style.display = "block";
              document.getElementById("selectedFind").style.display = "block";
            });
        }
      }
    },
  },
};
</script>