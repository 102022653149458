<template>
  <div class="mb-lg-0">
    <div>
      <div class="item2-gl">
        <div class="row">
          <div class="overflow-hidden">
            <div class="row">
              <div
                class="col-lg-4 col-md-12 col-xl-4"
                v-for="(item, i) in list"
                :key="'user' + i"
              >
                <div class="card overflow-hidden">
                  <div class="item-card9-imgs">
                    <div
                      style="height: 255px"
                      alt="img"
                      class="skeleton w-100"
                    ></div>
                  </div>
                  <div class="card-body">
                    <div class="item-card2">
                      <div class="item-card2-desc text-center">
                        <div class="item-card2-text">
                          <div class="skeleton text-dark p-3"></div>
                          <div class="skeleton text-dark p-2 mt-3"></div>
                        </div>
                        <p class="skeleton fs-16 mt-2 p-2"></p>
                        <div class="skeleton text-dark p-4 mt-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../../ProfileDetail/Components/Sidebar.vue";
import StarPos from "../../../components/StarPos.vue";
export default {
  props: ["list", "isLoad"],
  components: {
    Sidebar,
    StarPos,
  },
};
</script>