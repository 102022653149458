<template>
  <section>
    <div class="banner-1 cover-image sptb-2 centerTop">
      <div id="append" @click="close"></div>

      <div class="container mainTop">
        <div class="text-center text-white mb-6">
          <h1 class="mainText text-primary" style="letter-spacing: 2px">
            İhtiyacın Olan Terapi ve Terapisti Bul
          </h1>
        </div>
        <div class="row">
          <div class="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
            <div class="search-background">
              <div class="form row g-0">
                <div id="selectedFind">
                  <div
                    class="scrollList"
                    v-if="
                      $store.state.searchItem.experience != null &&
                      $store.state.searchItem.experience.length > 0
                    "
                  >
                    <li
                      v-for="(item, i) in $store.state.searchItem.experience"
                      :key="'experience' + i"
                      @click="
                        selectedIndex(item.experience_name, item.short_name)
                      "
                      class="select"
                    >
                      {{ item.experience_name }}
                    </li>
                  </div>
                  <hr
                    style="margin: 10px"
                    v-if="
                      $store.state.searchItem.users != null &&
                      $store.state.searchItem.users.length > 0
                    "
                  />

                  <div
                    class="expertlist"
                    v-if="
                      $store.state.searchItem.users != null &&
                      $store.state.searchItem.users.length > 0
                    "
                  >
                    <div class="expertlisttop"></div>
                    <div
                      v-for="(item, i) in $store.state.searchItem.users"
                      :key="'user' + i"
                      class="col-md-3 expertItem"
                    >
                      <template v-if="item.type == 'EXPERT'">
                        <router-link :to="'/terapist/' + item.shortlink">
                          <img
                            :src="
                              'https://storage.terapivitrini.com/' +
                              item.profile_image_url
                            "
                            width="70"
                            height="70"
                            style="height: 70px; width: 70px"
                            class="img-thumbnail rounded-circle"
                        /></router-link>
                      </template>
                      <template v-if="item.type == 'CENTER'">
                        <router-link :to="'/center/' + item.shortlink">
                          <img
                            :src="
                              'https://storage.terapivitrini.com/' +
                              item.profile_image_url
                            "
                            width="70"
                            height="70"
                            style="height: 70px; width: 70px"
                            class="img-thumbnail rounded-circle"
                        /></router-link>
                      </template>

                      <h6 style="text-align: center">
                        <StarPos
                          :pos="
                            Math.ceil(item.total_score / item.total_comment)
                          "
                          :id="item.id"
                        />
                        <template v-if="item.type == 'EXPERT'">
                          <router-link :to="'/terapist/' + item.shortlink">
                            {{ item.fullname }}</router-link
                          >
                        </template>
                        <template v-if="item.type == 'CENTER'">
                          <router-link :to="'/center/' + item.shortlink">
                            {{ item.fullname }}</router-link
                          >
                        </template>
                      </h6>
                    </div>
                  </div>
                  <div
                    v-if="
                      $store.state.searchItem.users &&
                      $store.state.searchItem.users.length == 0 &&
                      $store.state.searchItem.experience &&
                      $store.state.searchItem.experience.length == 0
                    "
                  >
                    <div class="text-center">Sonuç Bulunamadı</div>
                  </div>
                </div>

                <SearchInput />
                <div
                  @click="getCitys"
                  class="form-group col-xl-4 col-lg-4 select2-lg col-md-12 mb-0"
                >
                  <select
                    name="location"
                    id="location"
                    class="
                      form-control
                      select2-show-search
                      border-bottom-0
                      w-100
                    "
                    data-placeholder="Select"
                  >
                    <option selected disabled value="0">Konum</option>

                    <option
                      v-for="(item, i) in cityList"
                      :key="i"
                      :value="item.shortname"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-12 mb-0">
                  <button
                    @click="search"
                    class="btn btn-lg btn-block btn-secondary"
                    style="height: 100%; background: var(--primary-bg-hover)"
                  >
                    Ara
                  </button>
                </div>
              </div>
            </div>
            <div
              class="row mt-6 iconset"
              style="display: flex; justify-content: space-around;align-items: flex-start;"
            >
            <a target="_self" href="/terapist/filter/online-terapi" class="history-item">
                <div class="circle">
                  <i class="fa fa-video-camera"></i>
                </div>
                <label>Online Terapi</label>
              </a>
              <a target="_self" href="/terapist/filter/evde-terapi" class="history-item">
                <div class="circle">
                  <i class="fa fa-home"></i>
                </div>
                <label>Evde Terapi</label>
              </a>
              <a target="_self" href="/terapist/filter/yuz-yuze-terapi" class="history-item">
                <div class="circle">
                  <i class="fa fa-users"></i>
                </div>
                <label>Yüz yüze Terapi</label>
              </a>
              <a target="_self" href="/experiences" class="history-item">
                <div class="circle">
                  <i class="fa fa-heart"></i>
                </div>
                <label>Terapi</label>
              </a>
              <a target="_self" href="/terapist" class="history-item">
                <div class="circle">
                  <i class="fa fa-user-md"></i>
                </div>
                <label>Terapist</label>
              </a>
              <a target="_self" href="/education" class="history-item">
                <div class="circle">
                  <i class="fa fa-book"></i>
                </div>
                <label>Eğitim</label>
              </a>
              <a target="_self" href="/center" class="history-item">
                <div class="circle">
                  <i class="fa fa-university"></i>
                </div>
                <label>Kurum</label>
              </a>
              <a target="_self" href="/blog" class="history-item">
                <div class="circle">
                  <i class="fa fa-file-text"></i>
                </div>
                <label>Blog</label>
              </a>
            </div>
          </div>
        </div>
        <div class="text-center mt-5"></div>
      </div>
      <!-- /header-text -->
    </div>
  </section>
</template>
<style scope>
.app-icon{
  width: 169px;
    float: left;
    background: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 40px;
    box-shadow: 0px 5px 10px -3px #ddd;
    font-size: 19px;
    font-weight: 100;
    color: #bc7e9b;
    line-height: 35px;
  
}
.icon{
  width: 35px;
    float: left;
    height: 35px !important;
    padding: 5px;
}
#search::placeholder {
  color: #a15a7b86;
}
#location::-moz-selection {
  color: #bc7e9b;
}
.history-item{
  width: 70px;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
.history-item > label {
  display: inline-block;
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0.5rem;
}
.circle {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  float: left;
  color: #bc7e9b;
  background-color: rgb(255, 255, 255);
  box-shadow: 4px 4px 8px 2px #ddd;
  text-align: center;
    line-height: 5.5;
    
}
.circle:hover{
  background: #a15a7b4d !important;
}
.circle > i {
  font-size: 29px;
}
.banner-1
  .search-background
  .select2-container--default
  .select2-selection--single {
  border-radius: 20px !important;
}
.select2-dropdown {
  z-index: 5002;
}
.select2-selection__rendered {
  opacity: 0.5;
}
#append {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 5000;
  opacity: 0.2;
  display: none;
  background: black;
  height: -webkit-fill-available;
}
.scrollList {
  max-height: 85px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered{
  color: #bc7e9b !important;
}
.select2-selection__rendered{
  padding-left: 16px !important;
}
.select {
  padding: 5px;
}
.select:hover {
  background: #bc7e9b;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}
.bg-background2 {
  overflow: inherit !important;
}

#selectedFind:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #f7f7f7 transparent;
  position: absolute;
  transform: rotate(90deg);
  top: -15px;
  left: 65px;
}
#selectedFind {
  width: 100%;
  background-color: #f7f7f7;
  position: absolute;
  top: 50px;
  display: none;
  left: 0;
  z-index: 5001;
  box-shadow: 0px 13px 18px -10px #8e6478;
  border-radius: 10px;
  padding: 10px;
  color: black;
  margin-top: 12px;
}
.bottomButton:hover {
  background: #a15a7b4d !important;
}
.centerTop {
  margin: 0 auto;
  width: 100%;
  top: 30%;
}
.blog-list {
  overflow: scroll;

  overflow-x: hidden;
  overflow-y: hidden;
}
.blogItem {
  text-align: center;
  float: left;
}
.expertlist {
  overflow: scroll;
  height: 120px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}
.select2-selection__rendered {
  padding-left: 14px;
}
.expertlisttop {
}
.expertItem {
  text-align: center;
  float: left;
}
@media (max-width: 990px) {
  .iconset{
    display: none !important;
  }
  .history-item{
  width: 70px;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
.history-item > label {
  display: inline-block;
    font-size: 11px;
    text-align: center;
    margin-bottom: 0.5rem;
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  float: left;
  color: #bc7e9b;
  background-color: rgb(255, 255, 255);
  box-shadow: 4px 4px 8px 2px #ddd;
  text-align: center;
    line-height: 3;
    
}
.circle > i {
  font-size: 15px;
}
  .search-background {
    box-shadow: 0 15px 20px -12px #ccc;
  }
  #selectedFind:before {
    content: "\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f7f7f7 transparent transparent;
    position: absolute;
    transform: rotate(90deg);
    top: -15px;
    right: 50%;
    left: 50%;
  }
}
@media (max-width: 414px) {

  .mainText {
    font-size: 25px;
  }
  .form-group {
    box-shadow: 0 15px 6px -12px #ccc;
  }
  .scrollList {
    max-height: 93px;
  }
  .mainTop {
    position: absolute;
    top: 0px;
  }
  .expertItem {
    text-align: center;
  }
  .expertlist {
    overflow: scroll;
    overflow-x: hidden;
  }
}
</style>
<script>
import Footer2 from "./Footer2.vue";
import SearchInput from "./SearchInput.vue";
import StarPos from "./StarPos.vue";

export default {
  name: "Search",
  components: {
    SearchInput,
    StarPos,
    Footer2,
  },
  created() {
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        $(".select2-show-search").select2({
          minimumResultsForSearch: "",
          placeholder: "Select",
        });
      });
    }
  },
  data() {
    return {
      cityList: [],
      search_item: "",
    };
  },
  methods: {
    close() {
      document.getElementById("append").style.display = "none";
      document.getElementById("selectedFind").style.display = "none";
      if (document.getElementById("profile_sidebar")) {
        document
          .getElementById("profile_sidebar")
          .classList.remove("activeSelect");
      }
    },
    selectedIndex(item, short_link) {
      document.getElementById("search").value = item;
      this.search_item = short_link;
      document.getElementById("append").style.display = "none";
      document.getElementById("selectedFind").style.display = "none";
    },
    search() {
      let search = (this.$store.state.search =
        document.getElementById("search").value);

      if (this.search_item != "") {
        search = this.search_item;
      }
      let location = document.getElementById("location").value;
      if (search != "" && location != 0) {
        this.$router.push("/search?q=" + search + "&city=" + location);
      } else if (search == "" && location != 0) {
        this.$router.push("/search?q=&city=" + location);
      } else if (search != "" && location == 0) {
        this.$router.push("/search?q=" + search);
      } else {
        this.$vToastify.error("Değerler boş gönderilemez", "Arama Hatası");
      }
    },
    getCitys() {
      try {
        document.querySelector(".select2-search__field").focus();
        if (this.cityList.length == 0) {
          let cityInfo = {};

          this.$store.dispatch("citysGet", cityInfo).then((value) => {
            this.cityList = value.list;
            $(".select2-show-search").select2({
              minimumResultsForSearch: "",
              placeholder: "Search",
            });
          });
        }
      } catch (error) {}
    },
  },
};
</script>
